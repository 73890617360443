
import "./App.css";
import Routess from "./Routes";



function App() {
  return (
    <div>
      <Routess />
  
   
    </div>
  );
}

export default App;
