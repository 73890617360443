import React from "react";
import { BsFillPhoneFill } from "react-icons/bs";
import repairs from "../../assets/repairs.jpg"
export default function About() {
  return (
    <div className=" px-[5%]">
      <div className="my-[9%] ">
        <h6 className="text-center font-semibold text-lg">ABOUT EXPERIENCE</h6>
        <h3 className="text-center text-3xl font-semibold mt-[2%]">
          We Have Than 20 Years Experience in Gadget
          <br /> Smartphone & Laptop Repair Services
        </h3>
        <div className="grid grid-cols-3 gap-5 items-center mt-[4%]">
          <div className=" col-span-1 w-full h-[150px] bg-[#626262] rounded-md"></div>

          <div className=" col-span-1 w-full h-[150px] bg-[#626262] rounded-md"></div>
          <div className=" col-span-1 w-full h-[150px] bg-[#626262] rounded-md"></div>
        </div>
      </div>
      <div className="grid grid-cols-2 items-center gap-20">
        <div className="col-span-1 w-full">
          <img
            className="h-[700px] rounded-[30px] w-full object-cover"
            src={repairs}
            alt="repairs"
          />
        </div>
        <div className="col-span-1">
          <h5 className="mb-5">OUR INTRODUCTION</h5>
          <h2 className="text-[40px] font-bold leading-[50px]">
            Welcome To Smartphone & Laptop Repair Service Center
          </h2>
          <p className="mt-8">
            Your one-stop solution for mobile, iPad, and MacBook repairs. Fast
            and reliable service using genuine parts. Trust us to bring your
            devices back to life. Convenient, efficient, and dedicated to your
            satisfaction. Choose excellence for all your repair needs.
          </p>
          <div className="flex flex-col gap-7 my-7">
            <div className="w-full h-[120px] bg-white rounded-l-full shadow-2xl px-[4%] flex  gap-10 items-center">
              <div className="bg-black w-[70px] h-[70px] rounded-full flex  justify-center items-center">
                <BsFillPhoneFill className="text-white text-[35px]" />
              </div>
              <div className=" w-[80%] flex flex-col">
                <h4 className="font-bold text-[30px]">Repair Device</h4>
                <p>
                  Your one-stop solution for mobile, iPad, and MacBook repairs.
                </p>
              </div>
            </div>
            <div className="w-full h-[120px] bg-white rounded-l-full shadow-2xl px-[4%] flex  gap-10 items-center">
              <div className="bg-black w-[70px] h-[70px] rounded-full flex  justify-center items-center">
                <BsFillPhoneFill className="text-white text-[35px]" />
              </div>
              <div className=" w-[80%] flex flex-col">
                <h4 className="font-bold text-[30px]">Repair Device</h4>
                <p>
                  Your one-stop solution for mobile, iPad, and MacBook repairs.
                </p>
              </div>
            </div>
            <div className="">
              {" "}
              <button className="bg-black text-sm rounded-[8px] text-white px-8 py-4 mt-4">
                Get Started
              </button>
            
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
