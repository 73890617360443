import React from "react";
import NavFootLayout from "./layout/NavFootLayout";
import About from "./feautures/about";
import Hero from "./feautures/hero";
import FAQ from "./feautures/faq";
import Counter from "./feautures/counter";
import Skills from "./feautures/skills";

export default function Home() {
  return (
    <div className="max-w-[1440px] mx-auto">
      <NavFootLayout>
        <Hero />
        <Counter />
        <About />
<Skills/>
        <FAQ />
      </NavFootLayout>
    </div>
  );
}
