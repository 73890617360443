import React from "react";
import { BsFillPhoneFill } from "react-icons/bs";

export default function Skills() {
  return (
    <div className="px-[5%] my-[6%]">
      <div className="grid grid-cols-2 items-center gap-20">
        <div className="col-span-1 w-full"></div>
        <div className="col-span-1">
          <h5 className="mb-5">OUR SKILLS & EXPERTISE</h5>
          <h2 className="text-[40px] font-bold leading-[50px]">
            We Specialize In Quick & Professional Repairs
          </h2>
          <p>
            Your one-stop solution for mobile, iPad, and MacBook repairs. Fast
            and reliable service using genuine parts. Trust us to bring your
          </p>
          <p className="mt-4">
            devices back to life. Convenient, efficient, and dedicated to your
            satisfaction. Choose excellence for all your repair needs.
          </p>
          
        </div>
      </div>
    </div>
  );
}
