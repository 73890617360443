import React from "react";

export default function Footer() {
  return (
    <div className="bg-black">
      <div className="max-w-[1440px] mx-auto text-white">
        <div className="mt-[3%] py-[3%] px-[5%]">
          <h4 className="font-bold text-2xl">LOGO</h4>
          <div className="flex justify-between items-center">
            <div>
              
              <p>@2024. Elpizo Repairs. ALl Rights Reserved</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
