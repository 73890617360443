import React, { useState } from "react";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";

export default function Counter() {
  const [counterOn, setCounterOn] = useState(false);
  return (
    <ScrollTrigger
      onEnter={() => setCounterOn(true)}
      onExit={() => setCounterOn(false)}
    >
      <div className="bg-black   ">
        <div className="flex justify-between items-center  py-[5%] px-[5%]">
          <div>
            <h1 className="text-white text-[40px]">
              {counterOn && (
                <CountUp start={0} end={20} duration={2} delay={0} />
              )}
              k+
            </h1>
            <h5 className="text-white">Glorious Years</h5>
          </div>
          <div>
            {" "}
            <h1 className="text-white text-[40px]">
              {counterOn && (
                <CountUp start={0} end={2} duration={2} delay={0} />
              )}
              K+
            </h1>
            <h5 className="text-white">Happy Customer</h5>
          </div>
          <div>
            <h1 className="text-white text-[40px]">
              {counterOn && (
                <CountUp start={0} end={5} duration={2} delay={0} />
              )}
              k+
            </h1>
            <h5 className="text-white">Service Complete</h5>
          </div>
          <div>
            <h1 className="text-white text-[40px]">
              {counterOn && (
                <CountUp start={0} end={99} duration={2} delay={0} />
              )}
              %
            </h1>
            <h5 className="text-white">Satisfaction</h5>
          </div>
        </div>
      </div>
     
    </ScrollTrigger>
  );
}
